import React, { useState } from 'react';

type FAQ = {
  question: string;
  answer: string;
};

const faqData: FAQ[] = [
  {
    question: "What is Tango?",
    answer: "Tango is a platform that allows event organizers to create, promote, and sell event tickets both online and offline.",
  },
  {
    question: "How does it work?",
    answer: "Event organizers create their events on Tango Manager, set ticket prices, and availability. Attendees can then purchase tickets, while Tango handles payment processing and ticket delivery.",
  },
  {
    question: "Do I need to print physical tickets for my event?",
    answer: "No, event organizers do not need to print their own tickets. When attendees buy tickets online, they will receive a digital ticket with a QR code. For offline purchases, our trusted network of ticket distributors will print tickets using point-of-sale machines. Our QR code-based tickets are secure and ensure seamless entry at the venue.",
  },
  {
    question: "How do I get started selling tickets?",
    answer: "First, create an organizer account and add your payment details. Then submit your event for review. After approval, choose your ticket distributors. Your event will go live, and people can buy tickets online or in-person.",
  },
  {
    question: "What types of events can I create on Tango?",
    answer: "Tango supports all kinds of events, including concerts, festivals, conferences, workshops, classes, sports, fundraisers, parties, expos, and more. If it's an event, we can help you manage it!",
  },
  {
    question: "How much does it cost?",
    answer: "For tickets purchased online through our website or mobile app, we charge an 8% fee on the ticket price. Event organizers can choose to pass this fee on to the attendee, absorb it themselves, or split it. For tickets bought through our trusted network of ticket distributors, they typically charge a 5% fee, though this may vary by distributor.",
  },
  {
    question: "How do you handle Payouts?",
    answer: "For online ticket sales, event organizers can choose to receive payouts daily or weekly via bank transfer, EcoCash, or in-person at our Harare office. For offline tickets sold through our trusted network of ticket distributors, organizers can typically collect their funds weekly, after coordinating with the distributor.",
  },
  {
    question: "Can I issue refunds for my event?",
    answer: "Yes, as an event organizer, you can choose whether or not to support refunds for your event. If refunds are supported, they can be issued automatically when an event is canceled, for tickets purchased online. For offline tickets, ticket holders will need to go to the ticket distributor to collect their refunds.",
  },
  {
    question: "How do I scan tickets for my event?",
    answer: "Tickets can be scanned using the Tango Scanner mobile app. Alternatively, you can request our dedicated ticket scanning devices.",
  },
  {
    question: "Can I scan tickets offline?",
    answer: "Yes, offline scanning is possible. However, an active internet connection is required if you plan to use multiple ticket scanning devices.",
  },
  {
    question: "What should I do if I encounter technical issues?",
    answer: "Use the 'Feedback' feature in the app for bug reports or feature requests.",
  },
];

const OrgFAQComponent: React.FC = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const toggleFAQ = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="max-w-7xl mx-auto w-full p-4">
      <h2 className="text-2xl font-bold text-center mb-4">Frequently Asked Questions</h2>
      <p className="text-center mb-8 text-gray-600">
        Find the answers to the most common questions about our product. Feel free to contact us if you don’t find what you are looking for.
      </p>
      <div className="space-y-4">
        {faqData.map((faq, index) => (
          <div key={index} className="border rounded-md shadow-md">
            <button
              onClick={() => toggleFAQ(index)}
              className="w-full flex justify-between items-center p-4 text-left"
            >
              <span className="font-medium">{faq.question}</span>
              <span className="text-gray-500 transform transition-transform duration-300">
                {openIndex === index ? '▲' : '▼'}
              </span>
            </button>
            {openIndex === index && (
              <div className="p-4 text-gray-700 border-t">
                {faq.answer}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default OrgFAQComponent;
