import React from "react";
import tango from '../assets/images/Tango Africa logo 1.svg'
import { Link } from 'react-router-dom';

interface AuthLayoutProps {
  title: string; // The tagline or message displayed in the left section
  children: React.ReactNode; // The form or page content
}

const AuthLayout: React.FC<AuthLayoutProps> = ({ title, children }) => {
  return (
    <div className="flex flex-col md:flex-row min-h-screen">
      {/* Left Section - Hidden on mobile, visible on md and up */}
      <div className="hidden md:flex md:w-2/5 bg-[#0A5A5B] text-white flex-col justify-between p-8 lg:p-12">
        <div>
          <div className="mb-6">           
            <Link to="/">
              <img src={tango} alt="Tango Logo" className="w-40 lg:w-60"/>           
            </Link>
          </div>
          <p className="text-base lg:text-lg font-medium">{title}</p>
        </div>
        <div className="text-bold font-bold lg:text-[32px] mt-8 lg:mt-12">
          {/* Placeholder for footer text in the left section */}
          <p>Find More Of The <br/> Events You Love</p>
        </div>
      </div>

      {/* Mobile Header - Visible only on mobile */}
      <div className="md:hidden bg-[#0A5A5B] text-white p-6">
        <div className="flex flex-col items-center">
          <Link to="/">
            <img src={tango} alt="Tango Logo" className="w-40 mb-4"/>
          </Link>
          <p className="text-base font-medium text-center">{title}</p>
        </div>
      </div>

      {/* Right Section */}
      <div className="flex-1 md:w-3/5 bg-gray-50 flex items-center justify-center p-4 md:p-6">
        <div className="w-full max-w-md bg-white shadow-lg rounded-lg">
          <div className="p-4 md:p-6">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
