import React from 'react';

const CashoutTable: React.FC = () => {
  const tableData = [
    {
      agent: 'Tango Africa',
      commission: '8%',
      payoutType: 'In-Person, Bank Transfer, EcoCash',
      payoutInterval: 'Daily, Weekly',
      outlets: '1 (Online)',
      paymentOptions: "EcoCash, InnBucks, O'mari, Visa, Mastercard"
    },
    {
      agent: 'Diamond Pharmacy',
      commission: '5%',
      payoutType: 'In-Person',
      payoutInterval: 'Weekly',
      outlets: '5 (Offline)',
      paymentOptions: 'Cash'
    },
    {
      agent: 'MedOrange Pharmacy',
      commission: '5%',
      payoutType: 'In-Person',
      payoutInterval: 'Weekly',
      outlets: '3 (Offline)',
      paymentOptions: 'Cash'
    },
    {
      agent: 'Booties Pharmacy',
      commission: '0%',
      payoutType: 'In-Person',
      payoutInterval: 'Weekly',
      outlets: '2 (Offline)',
      paymentOptions: 'Cash'
    }
  ];

  return (
    <div className="w-full py-8">
      {/* <h2 className="text-3xl font-semibold mb-4">Cashout Table</h2>
      <p className="text-gray-600 mb-6">Our Cash out deals with agents</p> */}
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200">
          <thead>
            <tr className="bg-gray-50">
              <th className="px-6 py-3 text-left text-sm font-semibold text-gray-600">Ticket Distributor</th>
              <th className="px-6 py-3 text-left text-sm font-semibold text-gray-600">Commission</th>
              <th className="px-6 py-3 text-left text-sm font-semibold text-gray-600">Payout Type</th>
              <th className="px-6 py-3 text-left text-sm font-semibold text-gray-600">Payout Interval</th>
              <th className="px-6 py-3 text-left text-sm font-semibold text-gray-600">Outlets</th>
              <th className="px-6 py-3 text-left text-sm font-semibold text-gray-600">Payment Options</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((row, index) => (
              <tr 
                key={index}
                className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
              >
                <td className="px-6 py-4 text-sm text-gray-800">{row.agent}</td>
                <td className="px-6 py-4 text-sm text-gray-800">{row.commission}</td>
                <td className="px-6 py-4 text-sm text-gray-800">{row.payoutType}</td>
                <td className="px-6 py-4 text-sm text-gray-800">{row.payoutInterval}</td>
                <td className="px-6 py-4 text-sm text-gray-800">{row.outlets}</td>
                <td className="px-6 py-4 text-sm text-gray-800">{row.paymentOptions}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CashoutTable; 