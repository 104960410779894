import { supabase } from '../supabase/supabaseClient';

interface PromoCode {
  id: string;
  created_at: string;
  event_id: string;
  code: string;
  discount_value: number;
  max_uses: number;
  uses_count: number;
  is_active: boolean;
  organization_id: string;
}

interface CreatePromoCodeData {
  event_id: string;
  code: string;
  discount_value: number;
  max_uses: number;
  is_active?: boolean;
  organization_id: string;
}

interface UpdatePromoCodeData {
  code?: string;
  discount_value?: number;
  max_uses?: number;
  is_active?: boolean;
}

export class PromoCodesService {
  /**
   * Get all promo codes for an event
   * @param eventId - The ID of the event
   * @returns Promise with array of promo codes
   */
  async getEventPromoCodes(eventId: string): Promise<PromoCode[]> {
    try {
      const { data, error } = await supabase
        .from('event_promo_codes')
        .select('*')
        .eq('event_id', eventId);
        
      if (error) {
        console.error('Error fetching promo codes:', error);
        return [];
      }
      
      return data || [];
    } catch (error) {
      console.error('Error in getEventPromoCodes:', error);
      return [];
    }
  }
  
  /**
   * Get all active promo codes for an event
   * @param eventId - The ID of the event
   * @returns Promise with array of active promo codes
   */
  async getActiveEventPromoCodes(eventId: string): Promise<PromoCode[]> {
    try {
      const { data, error } = await supabase
        .from('event_promo_codes')
        .select('*')
        .eq('event_id', eventId)
        .eq('is_active', true);
        
      if (error) {
        console.error('Error fetching active promo codes:', error);
        return [];
      }
      
      return data || [];
    } catch (error) {
      console.error('Error in getActiveEventPromoCodes:', error);
      return [];
    }
  }
  
  /**
   * Validate a promo code for an event
   * @param eventId - The ID of the event
   * @param code - The promo code to validate
   * @returns Promise with the promo code if valid, null otherwise
   */
  async validatePromoCode(eventId: string, code: string): Promise<PromoCode | null> {
    try {
      const { data, error } = await supabase
        .from('event_promo_codes')
        .select('*')
        .eq('event_id', eventId)
        .eq('code', code)
        .eq('is_active', true)
        .single();
        
      if (error) {
        console.error('Error validating promo code:', error);
        return null;
      }
      
      // Check if the promo code has reached its maximum uses
      if (data.uses_count >= data.max_uses) {
        console.error('Promo code has reached maximum uses');
        return null;
      }
      
      return data;
    } catch (error) {
      console.error('Error in validatePromoCode:', error);
      return null;
    }
  }
  
  /**
   * Create a new promo code
   * @param promoCodeData - The promo code data
   * @returns Promise with the created promo code
   */
  async createPromoCode(promoCodeData: CreatePromoCodeData): Promise<PromoCode | null> {
    try {
      const { data, error } = await supabase
        .from('event_promo_codes')
        .insert({
          ...promoCodeData,
          uses_count: 0,
          is_active: promoCodeData.is_active !== undefined ? promoCodeData.is_active : true
        })
        .select()
        .single();
        
      if (error) {
        console.error('Error creating promo code:', error);
        return null;
      }
      
      return data;
    } catch (error) {
      console.error('Error in createPromoCode:', error);
      return null;
    }
  }
  
  /**
   * Update an existing promo code
   * @param promoCodeId - The ID of the promo code to update
   * @param updateData - The data to update
   * @returns Promise with the updated promo code
   */
  async updatePromoCode(promoCodeId: string, updateData: UpdatePromoCodeData): Promise<PromoCode | null> {
    try {
      const { data, error } = await supabase
        .from('event_promo_codes')
        .update(updateData)
        .eq('id', promoCodeId)
        .select()
        .single();
        
      if (error) {
        console.error('Error updating promo code:', error);
        return null;
      }
      
      return data;
    } catch (error) {
      console.error('Error in updatePromoCode:', error);
      return null;
    }
  }
  
  /**
   * Delete a promo code
   * @param promoCodeId - The ID of the promo code to delete
   * @returns Promise with success boolean
   */
  async deletePromoCode(promoCodeId: string): Promise<boolean> {
    try {
      const { error } = await supabase
        .from('event_promo_codes')
        .delete()
        .eq('id', promoCodeId);
        
      if (error) {
        console.error('Error deleting promo code:', error);
        return false;
      }
      
      return true;
    } catch (error) {
      console.error('Error in deletePromoCode:', error);
      return false;
    }
  }
  
  /**
   * Increment the uses count for a promo code
   * @param promoCodeId - The ID of the promo code
   * @returns Promise with the updated promo code
   */
  async incrementPromoCodeUses(promoCodeId: string): Promise<PromoCode | null> {
    try {
      // First get the current promo code to check max uses
      const { data: currentPromoCode, error: fetchError } = await supabase
        .from('event_promo_codes')
        .select('*')
        .eq('id', promoCodeId)
        .single();
        
      if (fetchError) {
        console.error('Error fetching promo code:', fetchError);
        return null;
      }
      
      // Check if incrementing would exceed max uses
      if (currentPromoCode.uses_count >= currentPromoCode.max_uses) {
        console.error('Cannot increment uses: already at maximum');
        return null;
      }
      
      // Increment the uses count
      const { data, error } = await supabase
        .from('event_promo_codes')
        .update({ 
          uses_count: currentPromoCode.uses_count + 1,
          // Automatically deactivate if reaching max uses
          is_active: currentPromoCode.uses_count + 1 < currentPromoCode.max_uses
        })
        .eq('id', promoCodeId)
        .select()
        .single();
        
      if (error) {
        console.error('Error incrementing promo code uses:', error);
        return null;
      }
      
      return data;
    } catch (error) {
      console.error('Error in incrementPromoCodeUses:', error);
      return null;
    }
  }
  
  /**
   * Get promo codes for an organization
   * @param organizationId - The ID of the organization
   * @returns Promise with array of promo codes
   */
  async getOrganizationPromoCodes(organizationId: string): Promise<PromoCode[]> {
    try {
      const { data, error } = await supabase
        .from('event_promo_codes')
        .select('*')
        .eq('organization_id', organizationId);
        
      if (error) {
        console.error('Error fetching organization promo codes:', error);
        return [];
      }
      
      return data || [];
    } catch (error) {
      console.error('Error in getOrganizationPromoCodes:', error);
      return [];
    }
  }
}

export const promoCodesService = new PromoCodesService(); 