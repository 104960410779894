import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import AuthLayout from "../layouts/Auth";
import { emailVerificationService } from "../Api/services/emailVerificationService";
import { userService, UserData } from "../Api/services/userService";
import { toast } from "react-toastify";

// Add validation helper functions at the top of the file
const isValidEmail = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

const isValidPhone = (phone: string): boolean => {
  // Allows for international format with optional +, minimum 10 digits
  const phoneRegex = /^\+?[\d\s-]{10,}$/;
  return phoneRegex.test(phone);
};

const isAtLeast18 = (dob: string): boolean => {
  const birthDate = new Date(dob);
  const today = new Date();
  const age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();
  
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
    return age - 1 >= 16;
  }
  return age >= 16;
};

const SignUpPage: React.FC = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isLoadingSendCode, setIsLoadingSendCode] = useState(false);
  const [isLoadingVerify, setIsLoadingVerify] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

  // User details state
  const [userData, setUserData] = useState<UserData>({
    email: "",
    first_name: "",
    last_name: "",
    phone: "",
    dob: "",
    gender: "",
  });

  const handleEmailChange = (value: string) => {
    setEmail(value);
    setUserData(prev => ({ ...prev, email: value }));
    if (!isValidEmail(value)) {
      toast.error("Please enter a valid email address");
      return;
    }
    if (isEmailVerified) {
      setIsEmailVerified(false);
      setIsEmailSent(false);
      setVerificationCode("");
    }
  };

  const handleInputChange = (field: keyof UserData, value: string) => {
    setUserData(prev => ({ ...prev, [field]: value }));
    
    // Validate phone number
    if (field === 'phone' && value && !isValidPhone(value)) {
      toast.error("Please enter a valid phone number");
      return;
    }

    // Validate date of birth
    if (field === 'dob' && value && !isAtLeast18(value)) {
      toast.error("You must be at least 18 years old to register");
      return;
    }
  };

  const handleSendVerificationCode = async () => {
    if (!email) {
      toast.error("Please enter your email address");
      return;
    }

    if (!isValidEmail(email)) {
      toast.error("Please enter a valid email address");
      return;
    }

    try {
      setIsLoadingSendCode(true);
      
      // First check if the email is already registered
      const isRegistered = await userService.checkEmailExists(email);
      
      if (isRegistered) {
        toast.error("This email is already registered. Please sign in instead.");
        return;
      }

      // If email is not registered, proceed with sending verification code
      const result = await emailVerificationService.sendVerificationCode(email);

      if (result.success) {
        setIsEmailSent(true);
        toast.success("Verification code sent successfully!");
      } else {
        toast.error(result.message || "Failed to send verification code");
      }
    } catch (error) {
      toast.error("Failed to send verification code. Please try again.");
    } finally {
      setIsLoadingSendCode(false);
    }
  };

  const handleVerifyCode = async () => {
    if (!verificationCode) {
      toast.error("Please enter the verification code");
      return;
    }

    try {
      setIsLoadingVerify(true);
      const result = await emailVerificationService.verifyCode(email, verificationCode);

      if (result.success) {
        setIsEmailVerified(true);
        toast.success("Email verified successfully!");
      } else {
        toast.error(result.message || "Invalid verification code");
      }
    } catch (error) {
      toast.error("Failed to verify code. Please try again.");
    } finally {
      setIsLoadingVerify(false);
    }
  };

  const handleSubmit = async () => {
    // Validate required fields
    const requiredFields: (keyof UserData)[] = ['first_name', 'last_name', 'phone', 'dob', 'gender'];
    const missingFields = requiredFields.filter(field => !userData[field]);
    
    if (missingFields.length > 0) {
      toast.error(`Please fill in all required fields: ${missingFields.join(', ')}`);
      return;
    }

    // Validate email format
    if (!isValidEmail(email)) {
      toast.error("Please enter a valid email address");
      return;
    }

    // Validate phone number
    if (!isValidPhone(userData.phone)) {
      toast.error("Please enter a valid phone number");
      return;
    }

    // Validate age
    if (!isAtLeast18(userData.dob)) {
      toast.error("You must be at least 18 years old to register");
      return;
    }

    try {
      setIsLoadingSubmit(true);
      const result = await userService.upsertUser(email, userData);
      
      if (result) {
        toast.success("Account created successfully!");
        setTimeout(() => {
          navigate('/signin');
        }, 1500);
      } else {
        toast.error("Failed to create account. Please try again.");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    } finally {
      setIsLoadingSubmit(false);
    }
  };

  return (
    <AuthLayout title="Join Tango Africa">
      <div className="bg-white rounded-lg">
        <h2 className="text-2xl font-bold text-gray-800 mb-6 text-center">
          Create Account
        </h2>

        <div className="space-y-4">
          {/* Combined Form */}
          <div className="space-y-4">
            {/* Email and Verification Section */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Email Address
                <span className="text-red-500 ml-1">*</span>
              </label>
              <input
                type="email"
                value={email}
                onChange={(e) => handleEmailChange(e.target.value)}
                className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-[#0A5A5B]"
                placeholder="Enter your email"
                disabled={isEmailVerified}
                required
              />
            </div>

            {isEmailSent && !isEmailVerified && (
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Verification Code
                  <span className="text-red-500 ml-1">*</span>
                </label>
                <input
                  type="text"
                  value={verificationCode}
                  onChange={(e) => setVerificationCode(e.target.value)}
                  className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-[#0A5A5B]"
                  placeholder="Enter verification code"
                />
              </div>
            )}

            {/* Personal Information */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  First Name
                  <span className="text-red-500 ml-1">*</span>
                </label>
                <input
                  type="text"
                  value={userData.first_name}
                  onChange={(e) => handleInputChange('first_name', e.target.value)}
                  className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-[#0A5A5B]"
                  placeholder="Enter first name"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Last Name
                  <span className="text-red-500 ml-1">*</span>
                </label>
                <input
                  type="text"
                  value={userData.last_name}
                  onChange={(e) => handleInputChange('last_name', e.target.value)}
                  className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-[#0A5A5B]"
                  placeholder="Enter last name"
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Phone Number
                <span className="text-red-500 ml-1">*</span>
              </label>
              <input
                type="tel"
                value={userData.phone}
                onChange={(e) => handleInputChange('phone', e.target.value)}
                className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-[#0A5A5B]"
                placeholder="Enter phone number"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Date of Birth
                <span className="text-red-500 ml-1">*</span>
              </label>
              <input
                type="date"
                value={userData.dob}
                onChange={(e) => handleInputChange('dob', e.target.value)}
                className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-[#0A5A5B]"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Gender
                <span className="text-red-500 ml-1">*</span>
              </label>
              <select
                value={userData.gender}
                onChange={(e) => handleInputChange('gender', e.target.value)}
                className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-[#0A5A5B]"
              >
                <option value="">Select gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
            </div>

            {/* Action Buttons */}
            {!isEmailVerified ? (
              <button
                onClick={isEmailSent ? handleVerifyCode : handleSendVerificationCode}
                disabled={!email || isLoadingSendCode || isLoadingVerify}
                className="w-full py-3 text-base font-medium rounded-lg bg-[#0A5A5B] text-white hover:bg-opacity-90 disabled:bg-gray-300 disabled:cursor-not-allowed transition-colors"
              >
                {isLoadingSendCode || isLoadingVerify ? (
                  <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin mx-auto"></div>
                ) : isEmailSent ? (
                  "Verify Code"
                ) : (
                  "Send Verification Code"
                )}
              </button>
            ) : (
              <button
                onClick={handleSubmit}
                disabled={isLoadingSubmit}
                className="w-full py-3 text-base font-medium rounded-lg bg-[#0A5A5B] text-white hover:bg-opacity-90 disabled:bg-gray-300 disabled:cursor-not-allowed transition-colors"
              >
                {isLoadingSubmit ? (
                  <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin mx-auto"></div>
                ) : (
                  "Create Account"
                )}
              </button>
            )}
          </div>
        </div>

        {/* Sign In Link */}
        <div className="mt-6 text-sm text-gray-500 text-center">
          Already have an account?{" "}
          <button 
            onClick={() => navigate('/signin')}
            className="text-[#0A5A5B] hover:underline font-medium"
          >
            Sign In
          </button>
        </div>

        {/* Terms and Privacy */}
        <div className="mt-4 md:mt-6 text-xs text-center text-gray-400">
        By continuing, you agree that you have read, understood, and acknowledge our{' '}
        <Link to="/privacy" className="text-[#0A5A5B] hover:underline">
          Privacy Policy
        </Link>{' '}
        and accept our{' '}
        <Link to="/terms" className="text-[#0A5A5B] hover:underline">
          General Terms of Service
        </Link>
        .
      </div>
      </div>
    </AuthLayout>
  );
};

export default SignUpPage; 