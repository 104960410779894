import React from "react";
import { Events } from "../components/events/Events";
import CreateEventCTA from "../components/events/CreateEvent";

const EventsPage: React.FC = () => {
  return (
    <div className="font-poppins flex flex-col gap-10">
      {/* Page Header */}
      <div className="bg-[#0B5B5C] text-white py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl md:text-4xl font-bold mb-4">
            Discover Amazing Events
          </h1>
          <p className="text-lg text-gray-200 max-w-2xl">
            Browse through our curated collection of events, from tech conferences to music festivals. Find your next unforgettable experience.
          </p>
        </div>
      </div>

      {/* Events Section */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <Events />
      </div>

      {/* Create Event CTA Section */}
      <div className="w-full">
        <CreateEventCTA />
      </div>
    </div>
  );
};

export default EventsPage; 