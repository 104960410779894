import { supabase } from '../supabase/supabaseClient'

export interface CustomQuestion {
  id: string
  created_at?: string
  question: string
  is_required: boolean
  options: Record<string, string>
  event_id: string
  organization_id: string
  answer_type: 'text' | 'number' | 'email' | 'single_choice' | 'multiple_choice'| 'boolean'
}

export class CustomQuestionsService {
  async getEventCustomQuestions(eventId: string): Promise<CustomQuestion[]> {
    const { data, error } = await supabase
      .from('event_custom_questions')
      .select('*')
      .eq('event_id', eventId)

    if (error) {
      throw new Error(error.message)
    }

    return data || []
  }
}

export const customQuestionsService = new CustomQuestionsService()

export async function createEventCustomAnswer(userId: string, eventId: string, answers: Array<{questionId: string, answer: string}>) {
  const formattedAnswers = answers.map((answer) => ({  
    answer: Array.isArray(answer.answer) ? answer.answer.join(', ') : answer.answer,
    event_id: eventId,
    question_id: answer.questionId.replace("*email", "").trim(),
    user_id: userId
  }));

  console.log('formatted', formattedAnswers);
  
  const { data, error } = await supabase
    .from('event_custom_answers')
    .insert(formattedAnswers);
    
  if (error) {
    console.error('Error inserting data:', error);
    throw error; // Throw error so it can be caught by the calling function
  }

  console.log('Inserted records:', data);
  return data;
} 