import { useState, useEffect } from 'react';
import { User } from '@supabase/supabase-js';
import { supabase } from '../Api/supabase/supabaseClient';

interface UseAuthReturn {
  isAuthenticated: boolean;
  user: User | null;
  checkAuth: () => Promise<void>;
  signOut: () => Promise<void>;
  setUser: (user: User | null) => void;
}

export const useAuth = (): UseAuthReturn => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<User | null>(null);

  const checkAuth = async (): Promise<void> => {
    console.log('Checking auth...'); // Debug log
    try {
      const { data: { user }, error } = await supabase.auth.getUser();
      console.log('Auth response:', { user, error }); // Debug log
      
      if (error) throw error;
      
      setUser(user);
      setIsAuthenticated(!!user);
    } catch (error) {
      console.error('Error checking auth status:', error);
      setUser(null);
      setIsAuthenticated(false);
    }
  };

  const signOut = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      
      // Clear all auth state
      setUser(null);
      setIsAuthenticated(false);
      localStorage.clear();
      
    } catch (error) {
      console.error('Error signing out:', error);
      throw error;
    }
  };

  useEffect(() => {
    console.log('Setting up auth listener...'); // Debug log
    checkAuth(); // Initial auth check

    const { data: { subscription } } = supabase.auth.onAuthStateChange((event, session) => {
      console.log('Auth state changed:', event, session); // Debug log
      if (event === 'SIGNED_OUT') {
        setUser(null);
        setIsAuthenticated(false);
      } else if (event === 'SIGNED_IN' || event === 'TOKEN_REFRESHED') {
        setUser(session?.user ?? null);
        setIsAuthenticated(!!session?.user);
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return {
    isAuthenticated,
    user,
    checkAuth,
    signOut,
    setUser
  };
}; 