import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { LandingPage } from "../pages/LandingPage";
import NavBar from "../components/NavBar/NavBar";
import { Events } from "../components/events/Events";
import "../App.css";
import { OrganizersPage } from "../pages/OrganizersPage";
import EventDetail from "../pages/EventDetailPage";
import PaymentStatus from "../pages/PaymentsPage";
import ScrollToTop from "../components/ScrollToTop";
import PaymentsMobile from "../pages/PaymentsMobile";
import SignInPage from "../pages/Signin";
import { PrivateRoute } from "../components/PrivateRoute";
import { useAuthContext } from "../contexts/AuthContext";
import SignUpPage from "../pages/Signup";
import BookingTickets from "../pages/UserTickets";
import UserTickets from "../pages/UserTickets";
import ProfilePage from "../pages/ProfilePage";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import TermsOfService from "../pages/TermsOfService";
import EventsPage from "../pages/EventsPage";


const AppContent = () => {
  const location = useLocation();
  const { isAuthenticated } = useAuthContext();

  const showNavBar = !location.pathname.includes('/sign') && 
                    !location.pathname.includes('/privacy') && 
                    !location.pathname.includes('/terms');

  return (
    <>
      {showNavBar && <NavBar />}
      <div className={showNavBar ? "pt-16" : ""}>
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<LandingPage />} />
          <Route path="/organizers" element={<OrganizersPage />} />
          <Route path="/events/:id" element={<EventDetail />} />
          <Route path="/payments-succes" element={<PaymentStatus />} />
          <Route path="/payment-success-web" element={<PaymentsMobile />} />
          <Route path="/tickets" element={<UserTickets />} />

          {/* Auth Routes */}
          <Route
            path="/signin"
            element={
              isAuthenticated ? (
                <Navigate to="/events" replace />
              ) : (
                <SignInPage />
              )
            }
          />
          <Route
            path="/signup"
            element={
              isAuthenticated ? (
                <Navigate to="/events" replace />
              ) : (
                <SignUpPage />
              )
            }
          />

          {/* Protected Routes */}
          <Route
            path="/events"
            element={
              <PrivateRoute>
                <EventsPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/tickets"
            element={
              <PrivateRoute>
                <UserTickets />
              </PrivateRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <PrivateRoute>
                <ProfilePage />
              </PrivateRoute>
            }
          />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<TermsOfService />} />
        </Routes>
      </div>
    </>
  );
};

export const AppRouter = () => {
  return <AppContent />;
};
