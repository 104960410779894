import { supabase } from '../supabase/supabaseClient'
import { Session } from '@supabase/supabase-js'

export class AuthService {
  async signInWithEmail(email: string): Promise<void> {
    try {
      const { error } = await supabase.auth.signInWithOtp({ email })
      if (error) throw error
      console.log('OTP sent to email!')
    } catch (error) {
      console.error('Error during sign in:', error)
      throw error
    }
  }

  async checkSession(): Promise<Session | null> {
    const { data: { session } } = await supabase.auth.getSession()
    return session
  }

  async verifyOtp(email: string, token: string): Promise<Session | null> {
    const {
      data: { session },
      error,
    } = await supabase.auth.verifyOtp({
      email,
      token,
      type: 'email',
    })

    if (error) {
      throw error
    }

    if (!session) {
      throw new Error('Failed to verify OTP - no session returned')
    }

    return session
  }

  async signOut(): Promise<void> {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
    } catch (error) {
      console.error('Error during sign out:', error);
      throw error;
    }
  }
}

export const authService = new AuthService() 