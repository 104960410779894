import React from 'react';
import QRCode from "react-qr-code";
import { toast } from 'react-toastify';
import { saveAs } from 'file-saver';
import html2canvas from 'html2canvas';
import logo from "../../assets/images/Tango Africa logo 1.svg";

interface TicketDetailsModalProps {
  isOpen: boolean;
  onClose: () => void;
  ticket: {
    id: string;
    date: string;
    title: string;
    location: string;
    tickets: string;
    image: string;
    qrCode?: string;
    isValid: boolean;
    ticketName?: string;
    events: {
      name: string;
      venue: string;
      start_time: string;
      end_time?: string;
      organizations?: any;
    };
  };
}

const TicketDetailsModal: React.FC<TicketDetailsModalProps> = ({
  isOpen,
  onClose,
  ticket
}) => {
  if (!isOpen) return null;

  const downloadTicket = async () => {
    try {
      const ticketElement = document.getElementById('ticket-details');
      if (!ticketElement) return;

      // Wait for images to load before capturing
      const images = ticketElement.getElementsByTagName('img');
      await Promise.all(Array.from(images).map(img => {
        if (img.complete) return Promise.resolve();
        return new Promise((resolve) => {
          img.onload = resolve;
          img.onerror = resolve; // Handle error case as well
        });
      }));

      const canvas = await html2canvas(ticketElement, {
        useCORS: true, // Enable CORS for external images
        allowTaint: true, // Allow cross-origin images
        logging: false,
      });

      canvas.toBlob((blob) => {
        if (blob) {
          saveAs(blob, `ticket-${ticket.id}.png`);
          toast.success('Ticket downloaded successfully!');
        }
      });
    } catch (error) {
      console.error('Error downloading ticket:', error);
      toast.error('Failed to download ticket');
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-2 sm:p-4">
      <div className="bg-white rounded-none w-full max-w-2xl max-h-[90vh] overflow-y-auto border border-zinc-200/50">
        {/* Header */}
        <div className="bg-[#0B5B5C] text-white p-3 sm:p-4 flex justify-between items-center sticky top-0 z-10">
          <h2 className="text-lg sm:text-xl font-semibold">Ticket Details</h2>
          <button
            onClick={onClose}
            className="text-white hover:text-gray-200 p-2"
          >
            ✕
          </button>
        </div>

        {/* Ticket content */}
        <div id="ticket-details" className="p-4 sm:p-6 bg-white">
          <div className="flex flex-col space-y-6">
            {/* Logo */}
            <div className="flex justify-between items-center">
              <img src={logo} alt="Tango Africa" className="h-12" />
              <div className="text-right">                
                <p className="font-semibold text-[#2F2E2E]">#{ticket.ticketName}</p>
              </div>
            </div>

            {/* Event Image and Title */}
            <div className="w-full">
              <div className="relative w-full h-48">
                <img
                  src={ticket.image}
                  alt={ticket.title}
                  className="absolute w-full h-full object-cover object-center"
                />
              </div>
              <h2 className="text-2xl font-bold mt-4 mb-2 text-[#2F2E2E] break-words">{ticket.title}</h2>
            </div>

            {/* Event Details */}
            <div className="w-full space-y-4">
              <div className="grid grid-cols-2 gap-4">
                <div className="bg-gray-50 p-4 rounded-none border border-zinc-200/50">
                  <p className="text-gray-600 text-sm mb-1">Date & Time</p>
                  <p className="font-semibold text-[#2F2E2E] break-words">
                    {new Date(ticket.events.start_time).toLocaleDateString()} at{' '}
                    {new Date(ticket.events.start_time).toLocaleTimeString()}
                  </p>
                </div>
                <div className="bg-gray-50 p-4 rounded-none border border-zinc-200/50">
                  <p className="text-gray-600 text-sm mb-1">Venue</p>
                  <p className="font-semibold text-[#2F2E2E] break-words">
                    {ticket.events.venue}
                  </p>
                </div>
              </div>

              <div className="bg-gray-50 p-4 rounded-none border border-zinc-200/50">
                <p className="text-gray-600 text-sm mb-1">Admission</p>
                <p className="font-semibold text-[#2F2E2E] break-words">{ticket.tickets}</p>
              </div>

              {/* Event Organizer */}
              <div className="bg-gray-50 p-4 rounded-none border border-zinc-200/50">
                <p className="text-gray-600 text-sm mb-1">Event Organizer</p>
                <p className="font-semibold text-[#2F2E2E] break-words">
                  {ticket.events.organizations?.name || 'Not specified'}
                </p>
              </div>
            </div>

            {/* QR Code */}
            {ticket.qrCode && ticket.isValid && (
              <div className="flex flex-col items-center space-y-4 bg-gray-50 p-6 rounded-none border border-zinc-200/50">
                <div className="p-4 bg-white border rounded-none shadow-md">
                  <QRCode
                    value={ticket.qrCode}
                    size={200}
                    level="H"
                  />
                </div>
                <p className="text-sm text-gray-600">
                  Present this QR code at the event entrance
                </p>
              </div>
            )}

            {/* Footer */}
            <div className="text-center text-sm text-gray-500 pt-4 border-t border-zinc-200/50">
              <p>Powered by Tango Africa</p>
              <p>www.tango.co.zw</p>
            </div>
          </div>
        </div>

        {/* Action Buttons */}
        <div className="p-4 sm:p-6 bg-gray-50 border-t border-zinc-200/50 flex flex-col sm:flex-row justify-end space-y-2 sm:space-y-0 sm:space-x-4">
          <button
            onClick={downloadTicket}
            className="w-full sm:w-auto px-6 py-2 bg-[#0B5B5C] text-white rounded-none hover:bg-[#0B5B5C]/90 transition-colors order-2 sm:order-1"
          >
            Download Ticket
          </button>
          <button
            onClick={onClose}
            className="w-full sm:w-auto px-6 py-2 border border-zinc-200/50 rounded-none hover:bg-gray-50 transition-colors text-[#2F2E2E] order-1 sm:order-2"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default TicketDetailsModal; 