import React from 'react';
import listEvent from '../../assets/images/list_event 1.svg';
import selectDistributors from '../../assets/images/select_distributor.svg';
import sellTickets from '../../assets/images/sell_tickets.svg';

const HowItWorks: React.FC = () => {
  const steps = [
    {
      number: 1,
      title: 'List your event on Tango',
      image: listEvent
    },
    {
      number: 2,
      title: 'Choose your ticket distributors',
      image: selectDistributors
    },
    {
      number: 3,
      title: 'Start selling your tickets',
      image: sellTickets
    }
  ];

  return (
    <div className="w-full py-16 bg-gray-200">
      <div className="text-center mb-12">
        <h2 className="text-2xl md:text-3xl font-bold text-gray-800 mb-4">How It Works</h2>
      </div>
      <div className="flex flex-col md:flex-row justify-between items-center gap-8 max-w-7xl mx-auto">
        {steps.map((step) => (
          <div key={step.number} className="flex flex-col items-center text-center max-w-sm gap-2">
            <div className="relative mb-6 flex flex-col items-center justify-center">
              <img 
                src={step.image} 
                alt={step.title} 
                className="w-48 h-48 object-contain"
              />
              <div className="mt-4">
                <span className="w-8 h-8 bg-teal-700 text-white rounded-full flex items-center justify-center text-lg font-semibold">
                  {step.number}
                </span>
              </div>
            </div>
            <h3 className="text-lg font-semibold mb-2">{step.title}</h3>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HowItWorks; 