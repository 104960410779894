import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer/Footer';
import logo from '../assets/images/Tango Africa logo 1.svg';

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
      {/* Header Section */}
      <div className="bg-[#0A5A5B] text-white py-12">
        <div className="container mx-auto px-4">
          <Link to="/" className="inline-block mb-6">
            <img src={logo} alt="Tango Africa" className="h-12 md:h-16" />
          </Link>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Privacy Policy</h1>
          <p className="text-lg opacity-90">Last updated: January 28, 2025</p>
        </div>
      </div>

      {/* Content Section */}
      <div className="container mx-auto px-4 py-8 max-w-4xl flex-grow">
        <div className="bg-white shadow-sm border border-zinc-200/50 p-6 md:p-8 space-y-6">
          {/* Introduction */}
          <section className="space-y-4">
            <p className="text-gray-600">
              Tango Africa (Private) Limited ("Tango Africa," "we," "us," or "our") is committed to protecting
              and respecting your privacy. This Privacy Policy outlines how we collect, use, disclose, and
              protect your personal information when you use our event marketplace platform, including all
              related mobile applications, websites, and services (collectively, the "Services").
            </p>
            <p className="text-gray-600">
              By using our Services, you agree to the collection and use of information in accordance with this
              Privacy Policy. If you do not agree with any part of this policy, please do not use our Services.
            </p>
          </section>

          {/* Information We Collect */}
          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-[#2F2E2E]">1. Information We Collect</h2>
            <p className="text-gray-600">We collect a variety of information to provide and improve our Services, including:</p>
            
            <div className="space-y-4">
              <h3 className="text-lg font-medium text-[#2F2E2E]">1.1 Personal Information</h3>
              <p className="text-gray-600">We collect personal information that you provide when creating an account, registering for events, making purchases, or interacting with our platform:</p>
              <ul className="list-disc list-inside text-gray-600 space-y-2 ml-4">
                <li>Account Information: Name, email address, phone number, payment information, and other contact details</li>
                <li>Event Information: Event details submitted by event organizers</li>
                <li>Payment Information: Bank account information or mobile payment service information</li>
              </ul>
            </div>

            <div className="space-y-4">
              <h3 className="text-lg font-medium text-[#2F2E2E]">1.2 Automatically Collected Information</h3>
              <ul className="list-disc list-inside text-gray-600 space-y-2 ml-4">
                <li>Log Data: IP address, browser type, device type, and access times</li>
                <li>Cookies and Tracking Technologies</li>
                <li>Location Information (with consent)</li>
              </ul>
            </div>
          </section>

          {/* How We Use Your Information */}
          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-[#2F2E2E]">How We Use Your Information</h2>
            <ul className="list-disc list-inside text-gray-600 space-y-2">
              <li>To process your ticket purchases</li>
              <li>To send you important event updates</li>
              <li>To improve our services</li>
              <li>To communicate with you about promotions and events</li>
              <li>To prevent fraud and ensure security</li>
            </ul>
          </section>

          {/* Data Security */}
          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-[#2F2E2E]">Data Security</h2>
            <p className="text-gray-600">
              We implement appropriate security measures to protect your personal information. 
              However, no method of transmission over the internet is 100% secure.
            </p>
          </section>

          {/* Contact Information */}
          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-[#2F2E2E]">13. Contact Information</h2>
            <p className="text-gray-600">
              If you have any questions or concerns about this Privacy Policy or how your personal data is handled, please contact us:
            </p>
            <ul className="text-gray-600 space-y-2">
              <li>Email: <a href="mailto:info@tango.co.zw" className="text-[#0A5A5B] hover:underline">info@tango.co.zw</a></li>
              <li>Phone: +263 773 018 727</li>
              <li>Address: 4th Floor, 3 Anchor House, Jason Moyo Ave, Harare, Zimbabwe</li>
            </ul>
          </section>

          {/* Acknowledgement */}
          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-[#2F2E2E]">Acknowledgement</h2>
            <p className="text-gray-600">
              By using Tango Africa's Services, you acknowledge that you have read, understood, and agree
              to be bound by this Privacy Policy.
            </p>
          </section>

          {/* Footer Navigation */}
          <div className="pt-6 border-t border-zinc-200/50">
            <Link 
              to="/terms" 
              className="text-[#0A5A5B] hover:underline"
            >
              View Terms of Service
            </Link>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default PrivacyPolicy; 