import React from 'react'
import Analytics from '../../assets/images/Analytics.svg'

export const OrganizersHero: React.FC = () => {
  return (
    <div className='bg-[#0B5B5C] w-full px-4 md:px-6 lg:px-8 pt-20 '>
        <div className='flex flex-col items-center justify-center gap-4 text-center'>
            <h1 className='text-white text-3xl p-2 md:text-5xl lg:text-[64px] font-bold leading-tight'>
              <span className=''>List Your Event On Tango,<br className="hidden md:block" /> Sell Your Tickets Everywhere</span>
             
            </h1>
            <p className='text-white text-base md:text-[18px] max-w-2xl mx-auto'>
            Reach ticket buyers wherever they are - be it online through our website or offline through our trusted network of ticket distributors across the country.
            </p>
        </div>
        <div className='mt-8 md:mt-12'>
            <div className='max-w-7xl mx-auto'>
              <img 
                src={Analytics} 
                alt="Analytics" 
                className='w-full h-auto object-contain' 
              />
            </div>
        </div>
    </div>
  )
}
