import React from 'react';

interface PaginationProps {
  activePage: number;
  itemsCountPerPage: number;
  totalItemsCount: number;
  pageRangeDisplayed: number;
  onChange: (pageNumber: number) => void;
  innerClass?: string;
  itemClass?: string;
  activeClass?: string;
}

const Pagination: React.FC<PaginationProps> = ({
  activePage,
  itemsCountPerPage,
  totalItemsCount,
  pageRangeDisplayed,
  onChange,
  innerClass = '',
  itemClass = '',
  activeClass = ''
}) => {
  const pageCount = Math.ceil(totalItemsCount / itemsCountPerPage);
  const pages = Array.from({ length: pageCount }, (_, i) => i + 1);

  // Calculate range of pages to display
  const startPage = Math.max(1, activePage - Math.floor(pageRangeDisplayed / 2));
  const endPage = Math.min(pageCount, startPage + pageRangeDisplayed - 1);

  return (
    <div className={innerClass}>
      {/* Previous button */}
      <button
        onClick={() => onChange(activePage - 1)}
        disabled={activePage === 1}
        className={`${itemClass} ${activePage === 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
      >
        &lt;
      </button>

      {/* First page */}
      {startPage > 1 && (
        <>
          <button
            onClick={() => onChange(1)}
            className={`${itemClass} ${activePage === 1 ? activeClass : ''}`}
          >
            1
          </button>
          {startPage > 2 && <span>...</span>}
        </>
      )}

      {/* Page numbers */}
      {pages.slice(startPage - 1, endPage).map(page => (
        <button
          key={page}
          onClick={() => onChange(page)}
          className={`${itemClass} ${activePage === page ? activeClass : ''}`}
        >
          {page}
        </button>
      ))}

      {/* Last page */}
      {endPage < pageCount && (
        <>
          {endPage < pageCount - 1 && <span>...</span>}
          <button
            onClick={() => onChange(pageCount)}
            className={`${itemClass} ${activePage === pageCount ? activeClass : ''}`}
          >
            {pageCount}
          </button>
        </>
      )}

      {/* Next button */}
      <button
        onClick={() => onChange(activePage + 1)}
        disabled={activePage === pageCount}
        className={`${itemClass} ${activePage === pageCount ? 'opacity-50 cursor-not-allowed' : ''}`}
      >
        &gt;
      </button>
    </div>
  );
};

export default Pagination;
  