import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import AuthLayout from "../layouts/Auth";
import { emailVerificationService } from "../Api/services/emailVerificationService";
import { useAuthContext } from '../contexts/AuthContext';
import { toast } from "react-toastify";

const SignInPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated, checkAuth } = useAuthContext();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [email, setEmail] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isLoadingSendCode, setIsLoadingSendCode] = useState(false);
  const [isLoadingVerify, setIsLoadingVerify] = useState(false);

  useEffect(() => {
    const initializeAuth = async () => {
      try {
        await checkAuth();
      } finally {
        setIsPageLoading(false);
      }
    };

    initializeAuth();
  }, [checkAuth]);

  useEffect(() => {
    if (isAuthenticated && !isPageLoading) {
      const from = (location.state as any)?.from?.pathname || "/events";
      navigate(from, { replace: true });
    }
  }, [isAuthenticated, isPageLoading, location.state, navigate]);

  const handleSendCode = async () => {
    if (!email) {
      toast.error("Please enter your email address");
      return;
    }

    try {
      setIsLoadingSendCode(true);
      const result = await emailVerificationService.sendVerificationCode(email);

      if (result.success) {
        setIsEmailSent(true);
        toast.success("Verification code sent successfully!");
      } else {
        toast.error(result.message || "Failed to send verification code");
      }
    } catch (error) {
      toast.error("Failed to send verification code. Please try again.");
    } finally {
      setIsLoadingSendCode(false);
    }
  };

  const handleVerifyCode = async () => {
    if (!verificationCode) {
      toast.error("Please enter the verification code");
      return;
    }

    try {
      setIsLoadingVerify(true);
      const result = await emailVerificationService.verifyCode(email, verificationCode);

      if (result.success) {
        await checkAuth();
        toast.success("Successfully signed in!");
        const from = (location.state as any)?.from?.pathname || "/events";
        navigate(from, { replace: true });
      } else {
        toast.error(result.message || "Invalid verification code");
      }
    } catch (error) {
      toast.error("Failed to verify code. Please try again.");
    } finally {
      setIsLoadingVerify(false);
    }
  };

  if (isPageLoading) {
    return (
      <AuthLayout title="Find More Of The Events You Love">
        <div className="flex justify-center items-center min-h-[200px]">
          <div className="w-8 h-8 border-4 border-[#0A5A5B] border-t-transparent rounded-full animate-spin"></div>
        </div>
      </AuthLayout>
    );
  }

  return (
    <AuthLayout title="">
      <h2 className="text-xl md:text-2xl font-bold text-gray-800 mb-4 md:mb-6 text-center">
        Sign In
      </h2>

      {/* Email Input */}
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="email">
          Email Address
        </label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="w-full p-2.5 md:p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#0A5A5B] text-sm md:text-base"
          disabled={isEmailSent}
        />
      </div>

      {/* Verification Code Input */}
      {isEmailSent && (
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="code">
            Verification Code
          </label>
          <input
            type="text"
            id="code"
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
            className="w-full p-2.5 md:p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#0A5A5B] text-sm md:text-base"
          />
        </div>
      )}

      {/* Action Button */}
      <button
        onClick={isEmailSent ? handleVerifyCode : handleSendCode}
        disabled={isLoadingSendCode || isLoadingVerify}
        className="w-full bg-[#0A5A5B] text-white py-2.5 md:py-3 px-4 rounded-md hover:bg-opacity-90 disabled:bg-gray-400 disabled:cursor-not-allowed mt-2 text-sm md:text-base font-medium"
      >
        {isLoadingSendCode || isLoadingVerify ? (
          <div className="flex justify-center">
            <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin"></div>
          </div>
        ) : isEmailSent ? (
          "Verify Code"
        ) : (
          "Send Code"
        )}
      </button>

      {/* Resend Code */}
      {isEmailSent && (
        <button
          onClick={handleSendCode}
          disabled={isLoadingSendCode}
          className="w-full mt-2 text-[#0A5A5B] hover:underline text-xs md:text-sm text-center"
        >
          Resend Code
        </button>
      )}

      <div className="mt-4 md:mt-6 text-xs md:text-sm text-gray-500 text-center">
        Don't have an account?{" "}
        <button 
          onClick={() => navigate('/signup')}
          className="text-[#0A5A5B] hover:underline font-medium"
        >
          Sign Up
        </button>
      </div>

      <div className="mt-4 md:mt-6 text-xs text-center text-gray-400">
        By continuing, you agree that you have read, understood, and acknowledge our{' '}
        <Link to="/privacy" className="text-[#0A5A5B] hover:underline">
          Privacy Policy
        </Link>{' '}
        and accept our{' '}
        <Link to="/terms" className="text-[#0A5A5B] hover:underline">
          General Terms of Service
        </Link>
        .
      </div>
    </AuthLayout>
  );
};

export default SignInPage;
