import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer/Footer';
import logo from '../assets/images/Tango Africa logo 1.svg';

const TermsOfService: React.FC = () => {
  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
      {/* Header Section */}
      <div className="bg-[#0A5A5B] text-white py-12">
        <div className="container mx-auto px-4">
          <Link to="/" className="inline-block mb-6">
            <img src={logo} alt="Tango Africa" className="h-12 md:h-16" />
          </Link>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Terms of Service</h1>
          <p className="text-lg opacity-90">Last updated: January 28, 2025</p>
        </div>
      </div>

      {/* Content Section */}
      <div className="container mx-auto px-4 py-8 max-w-4xl flex-grow">
        <div className="bg-white shadow-sm border border-zinc-200/50 p-6 md:p-8 space-y-6">
          {/* Introduction */}
          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-[#2F2E2E]">1. Introduction</h2>
            <p className="text-gray-600">
              Welcome to Tango Africa. These Terms of Service ("Terms") govern your access to and use of
              the Tango Africa platform, including all related mobile applications, websites, and services
              (collectively, the "Services"). By using our Services, you agree to comply with and be bound by
              these Terms. Please read them carefully.
            </p>
            <p className="text-gray-600">
              Tango Africa (Private) Limited ("Tango Africa," "Tango," "we," "us," or "our") operates an event
              registration and ticketing platform that allows event organizers ("Organizers") to create, manage,
              and promote events, and sell tickets to attendees ("Attendees").
            </p>
            <p className="text-gray-600">
              If you do not agree with any part of these Terms, you must immediately cease using our
              Services.
            </p>
          </section>

          {/* Acceptance of Terms */}
          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-[#2F2E2E]">2. Acceptance of Terms</h2>
            <p className="text-gray-600">
              By accessing or using the Services, you agree to be bound by these Terms, our Privacy Policy,
              and any other applicable policies or rules. These Terms may be updated periodically, and any
              changes will be communicated through the platform or via email. Your continued use of the
              Services after such changes constitutes your acceptance of the revised Terms.
            </p>
          </section>

          {/* Definitions */}
          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-[#2F2E2E]">3. Definitions</h2>
            <ul className="space-y-2 text-gray-600">
              <li><strong>Platform</strong> means Tango Africa's websites, mobile applications, and related services.</li>
              <li><strong>Event</strong> means any event created, listed, or managed on the Platform by an Organizer.</li>
              <li><strong>Organizer</strong> means any individual or entity creating, managing, or promoting an Event on the Platform.</li>
              <li><strong>Attendee</strong> means any individual purchasing or holding a ticket for an Event through the Platform.</li>
              <li><strong>Content</strong> means all data, text, images, videos, audio, and other materials submitted by users.</li>
              <li><strong>Commission</strong> means the service fee charged by Tango Africa on each ticket sold through the Platform.</li>
              <li><strong>Payment Methods</strong> means the payment options available on the Platform for ticket transactions.</li>
              <li><strong>Force Majeure</strong> refers to extraordinary events or circumstances beyond the control of either party.</li>
            </ul>
          </section>

          {/* Use License */}
          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-[#2F2E2E]">Use License</h2>
            <div className="space-y-3 text-gray-600">
              <p>Permission is granted to temporarily access our services for personal use only. This license shall automatically terminate if you violate any of these restrictions:</p>
              <ul className="list-disc list-inside space-y-2">
                <li>Reselling or unauthorized distribution of tickets</li>
                <li>Using our platform for fraudulent purposes</li>
                <li>Attempting to bypass security measures</li>
                <li>Violating event organizer policies</li>
              </ul>
            </div>
          </section>

          {/* Ticket Policies */}
          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-[#2F2E2E]">Ticket Policies</h2>
            <ul className="list-disc list-inside text-gray-600 space-y-2">
              <li>All tickets are subject to availability</li>
              <li>Tickets may not be resold above face value</li>
              <li>We reserve the right to cancel tickets obtained fraudulently</li>
              <li>Refund policies are set by individual event organizers</li>
            </ul>
          </section>

          {/* Disclaimer */}
          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-[#2F2E2E]">Disclaimer</h2>
            <p className="text-gray-600">
              Our services are provided "as is". We make no warranties, expressed or implied, and hereby 
              disclaim all warranties, including without limitation implied warranties or conditions of 
              merchantability.
            </p>
          </section>

          {/* Limitation of Liability */}
          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-[#2F2E2E]">Limitation of Liability</h2>
            <p className="text-gray-600">
              We shall not be liable for any indirect, incidental, special, consequential or punitive damages 
              resulting from your use of our services.
            </p>
          </section>

          {/* Contact Information */}
          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-[#2F2E2E]">15. Contact Information</h2>
            <p className="text-gray-600">For questions regarding these Terms, please contact us at:</p>
            <ul className="text-gray-600 space-y-2">
              <li>Email: <a href="mailto:info@tango.co.zw" className="text-[#0A5A5B] hover:underline">info@tango.co.zw</a></li>
              <li>Phone: 0773 018 727</li>
              <li>Address: 4th Floor, 3 Anchor House, Jason Moyo Ave, Harare, Zimbabwe</li>
            </ul>
          </section>

          {/* Acknowledgement */}
          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-[#2F2E2E]">16. Acknowledgement</h2>
            <p className="text-gray-600">
              By using Tango Africa's Services, you acknowledge that you have read, understood, and agree
              to be bound by these Terms of Service.
            </p>
          </section>

          {/* Footer Navigation */}
          <div className="pt-6 border-t border-zinc-200/50">
            <Link 
              to="/privacy" 
              className="text-[#0A5A5B] hover:underline"
            >
              View Privacy Policy
            </Link>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default TermsOfService; 