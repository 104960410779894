import React, { useEffect, useState } from "react";

const Statistics: React.FC = () => {
  const stats = [
    { end: 48, label: "Events Listed" },
    { end: 14094, label: "Tickets Sold" },
    { end: 23, label: "Happy Organizers" },
  ];

  const [counts, setCounts] = useState<number[]>([0, 0, 0]);

  useEffect(() => {
    stats.forEach((stat, index) => {
      let start = 0;
      const increment = stat.end / (2 * 60); // Adjust duration here (2 seconds with 60 FPS)
      const timer = setInterval(() => {
        start += increment;
        if (start >= stat.end) {
          clearInterval(timer);
          setCounts((prevCounts) => {
            const newCounts = [...prevCounts];
            newCounts[index] = stat.end;
            return newCounts;
          });
        } else {
          setCounts((prevCounts) => {
            const newCounts = [...prevCounts];
            newCounts[index] = Math.floor(start);
            return newCounts;
          });
        }
      }, 1000 / 60); // 60 FPS

      return () => clearInterval(timer);
    });
  }, []);

  return (
    <div className="max-w-7xl mx-auto my-8">
      <div className="flex flex-col md:flex-row justify-between items-center gap-8  rounded-md p-8">
        {stats.map((stat, index) => (
          <div key={index} className="text-center">
            <p className="text-[80px] font-bold text-[#0B5B5C]">
              {counts[index]}
            </p>
            <p className="text-lg font-semibold text-gray-800">{stat.label}</p>
          </div>
        ))}
      </div>
      <div className="text-center mt-8">
        <h2 className="text-2xl md:text-3xl font-bold text-gray-800">What We Offer</h2>
        <p className="text-gray-700 mt-4 max-w-2xl mx-auto">
        Tango provides a seamless ticketing solution that lets you sell tickets both online and in physical stores. With our platform, you can easily list your event, manage sales, and track attendance, while our network of trusted partners offers your customers the option to purchase tickets in person. Whether your audience prefers to buy online or offline, Tango maximizes your reach, offering a flexible and secure ticketing experience for both organizers and attendees.
        </p>
      </div>
    </div>
  );
};

export default Statistics;
