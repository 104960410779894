import React, { useState } from 'react';
import QRCode from "react-qr-code";
import TicketDetailsModal from '../Modals/TicketDetailsModal';

interface TicketCardProps {
    id: string;
    date: string;
    title: string;
    location: string;
    admission: number;
    price: string;
    image: string;
    qrCode?: string;
    isValid?: boolean;
    events: {
        name: string;
        venue: string;
        start_time: string;
        end_time?: string;
        organizations?: {
            name: string;
        };
    };
    ticketName?: string;
}

const TicketCard: React.FC<TicketCardProps> = ({
    id,
    date,
    title,
    location,
    admission,
    price,
    image,
    qrCode,
    isValid = true,
    events,
    ticketName
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    console.log(events);

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', {
            weekday: 'short',
            day: 'numeric',
            month: 'short',
            year: 'numeric',
            hour: 'numeric',
            minute: '2-digit',
            hour12: true
        });
    };

    const truncateVenue = (venue: string, maxLength: number = 25) => {
        return venue.length > maxLength ? `${venue.substring(0, maxLength)}...` : venue;
    };

    return (
        <>
            <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between border border-zinc-200/50 rounded-none p-4 hover:shadow-lg transition-shadow bg-white">
                {/* Left Section */}
                <div className="flex items-start sm:items-center flex-1 w-full sm:w-auto mb-4 sm:mb-0">
                    <img
                        src={image}
                        alt={title}
                        className="w-20 h-20 sm:w-24 sm:h-24 object-cover rounded-none mr-4"
                    />
                    <div>
                        <p className="text-sm text-gray-500">{formatDate(events.start_time)}</p>
                        <h3 className="text-lg font-bold text-[#2F2E2E]">{title}</h3>
                        <p className="text-sm text-gray-500 truncate max-w-[200px]" title={events.venue}>
                            {truncateVenue(events.venue)}
                        </p>
                        <div className='flex flex-row  gap-2 '>
                            <p>{admission} {ticketName} Ticket(s)</p>
                            
                        </div>
                    </div>
                </div>

                {/* Right Section - Stack on mobile */}
                <div className="flex flex-col sm:flex-row items-stretch sm:items-center gap-4 sm:gap-6 w-full sm:w-auto">
                    {/* <div className="text-sm border border-[#0B5B5C]/20 rounded-none px-4 py-2.5 text-[#0B5B5C] min-w-[140px] text-center flex items-center justify-center">
                        <div className='flex flex-row items-center gap-2 justify-center'>
                            <p>Admission: {admission}</p>
                            <p>{ticketName}</p>
                        </div>
                    </div> */}
                    
                    <button 
                        onClick={() => setIsModalOpen(true)}
                        className="text-sm w-full sm:w-[140px] bg-[#0B5B5C] text-white px-4 py-2.5 rounded-none hover:bg-[#0B5B5C]/90 transition-colors flex items-center justify-center min-h-full"
                    >
                        View Details
                    </button>
                </div>
            </div>

            <TicketDetailsModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                ticket={{
                    id,
                    date,
                    title,
                    location,
                    tickets: `${admission}`,
                    image,
                    qrCode,
                    ticketName,
                    isValid,
                    events
                }}
            />
        </>
    );
};

export default TicketCard;
  