import { supabase } from '../supabase/supabaseClient';
import { toast } from 'react-toastify';

export interface UserData {
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
  dob: string;
  gender: string;
}

export class UserService {
  async upsertUser(userId: string, userData: UserData) {
    try {
      const { data, error } = await supabase
        .from("users")
        .update({
          email: userData.email,
          first_name: userData.first_name,
          last_name: userData.last_name,
          phone: userData.phone,
          dob: userData.dob,
          gender: userData.gender,
        })
        .eq("id", userId)
        .select("*")
        .single();

      if (error) {
        console.error('Error upserting user data:', error);
        toast.error('Failed to update user information');
        return null;
      }

      console.log('Upserted user records:', data);
      toast.success('User information updated successfully');
      return data;

    } catch (error) {
      console.error('Error in upsertUser:', error);
      return null;
    }
  }

  // Add method to get user by email
  async getUserByEmail(email: string) {
    try {
      const { data, error } = await supabase
        .from('users')
        .select('*')
        .eq('email', email)
        .single();

      if (error) {
        console.error('Error fetching user:', error);
        return null;
      }

      return data;
    } catch (error) {
      console.error('Error in getUserByEmail:', error);
      return null;
    }
  }

  async checkEmailExists(email: string): Promise<boolean> {
    try {
      const { data, error } = await supabase
        .from('users')
        .select('id')
        .eq('email', email)
        .single();

      if (error && error.code === 'PGRST116') {
        // PGRST116 means no rows returned, which means email doesn't exist
        return false;
      }

      // If we got data, the email exists
      return !!data;
    } catch (error) {
      console.error('Error checking email:', error);
      throw error;
    }
  }
}

export const userService = new UserService(); 