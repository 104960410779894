import React, { useState, useEffect } from 'react';
import Partner1 from '../../assets/images/Patner.jpg'
import Partner2 from '../../assets/images/Patner2.jpg'
import Partner3 from '../../assets/images/Adsco.jpg'
import Partner4 from '../../assets/images/Patner3.jpg'
import Partner5 from '../../assets/images/OtakuCon.jpg'
import Partner6 from '../../assets/images/Ztn.jpg'

interface Testimonial {
  id: number;
  quote: string;
  name: string;
  position: string;
  image: string;
}

const testimonials: Testimonial[] = [
  {
    id: 1,
    quote: 'Our events were challenging to manage before Tango Africa. Tracking registrations and payments was time-consuming and error-prone. The platform has significantly streamlined our process. We\'ve seen a notable increase in attendance, and the administrative burden has decreased substantially.',
    name: 'Taffie Matiure',
    position: 'Theatre In The Park',
    image: Partner4,
  },
  {
    id: 2,
    quote: 'Music festivals require precise event management. Tango Africa provides a straightforward solution for ticket sales and event registration. The platform\'s mobile interface allows attendees to purchase tickets easily, and the event creation process is intuitive. During our last festival, I could concentrate on the event content rather than getting bogged down in administrative details.',
    name: 'Marge',
    position: 'Event Coordinator, Love Faith Festival',
    image: Partner2,
  },
  // {
  //   id: 3,
  //   quote: 'Business conferences are unpredictable by nature. Tango Africa doesn\'t eliminate every challenge, but it has introduced a level of predictability to our event management. The platform allows us to adapt quickly - whether it\'s adjusting ticket allocations or understanding our audience demographics.',
  //   name: 'Paidamoyo Maringwa',
  //   position: 'Event Coordinator, Women Rise Conference',
  //   image: Partner3,
  // },
  {
    id: 4,
    quote: 'Event planning involves multiple moving parts, and Tango Africa has simplified our workflow significantly. The real-time analytics give us immediate insights into ticket sales, allowing us to make quick marketing adjustments. Creating event tickets is now a matter of minutes, not hours.',
    name: 'OtakuKon',
    position: 'Event Organizer',
    image: Partner5,
  },
  {
    id: 5,
    quote: 'The real-time analytics on Tango Africa have transformed how we understand our event attendance. We can now track ticket sales instantly, adjust our marketing strategies mid-campaign, and get immediate insights into our audience demographics.',
    name: 'Tapiwa',
    position: 'Asco Events',
    image: Partner3,
  },
  {
    id: 6,
    quote: 'Creating event tickets used to be a time-consuming process. Tango Africa\'s mobile-friendly interface has completely changed that. I can set up an entire event page in minutes, and our attendees can purchase tickets directly from their phones. The simplicity of the platform has made event registration almost effortless.',
    name: 'Bhana HarshaI',
    position: 'Fort Infinity',
    image: Partner1,
  },
  {
    id: 7,
    quote: 'Event planning involves numerous moving parts, and managing ticket sales can be particularly complex. Tango Africa has helped us simplify this process. While no solution is perfect, the platform has reduced our administrative workload and provided a more reliable way to handle event registrations.',
    name: 'Rumbi Dendere',
    position: 'ZTN',
    image: Partner6,
  },
];

const TestimonialCarousel: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAutoPlaying, setIsAutoPlaying] = useState(true);
  
  // Auto-scroll effect
  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    
    if (isAutoPlaying) {
      intervalId = setInterval(() => {
        handleNext();
      }, 3000); // Changed from 5000 to 3000 (3 seconds)
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [currentIndex, isAutoPlaying]);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  // Pause auto-scroll on hover
  const handleMouseEnter = () => setIsAutoPlaying(false);
  const handleMouseLeave = () => setIsAutoPlaying(true);

  const getVisibleTestimonials = () => {
    const visibleItems = [];
    for (let i = 0; i < 3; i++) {
      const index = (currentIndex + i) % testimonials.length;
      visibleItems.push(testimonials[index]);
    }
    return visibleItems;
  };

  return (
    <div className="text-center max-w-6xl mx-auto py-8 md:py-12 px-4">
      <h2 className="text-xl md:text-2xl font-semibold mb-6">What Our Customers Say</h2>
      <div 
        className="relative" 
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="hidden md:flex gap-6 transition-all duration-700">
          {getVisibleTestimonials().map((testimonial, index) => (
            <div
              key={testimonial.id}
              className="transform transition-all duration-700"
              style={{
                opacity: index === 1 ? '1' : '0.7',
                scale: index === 1 ? '1' : '0.95',
              }}
            >
              <TestimonialCard testimonial={testimonial} />
            </div>
          ))}
        </div>
        
        {/* Mobile view - single testimonial with fade transition */}
        <div className="md:hidden">
          <div className="transition-opacity duration-700 ease-in-out">
            <TestimonialCard testimonial={testimonials[currentIndex]} />
          </div>
        </div>

        {/* Navigation buttons */}
        <div className="absolute top-1/2 transform -translate-y-1/2 -left-2 md:-left-4">
          <button
            onClick={handlePrev}
            className="bg-gray-100 hover:bg-gray-200 rounded-full p-2 shadow-md transition-all duration-300"
          >
            ❮
          </button>
        </div>
        <div className="absolute top-1/2 transform -translate-y-1/2 -right-2 md:-right-4">
          <button
            onClick={handleNext}
            className="bg-gray-100 hover:bg-gray-200 rounded-full p-2 shadow-md transition-all duration-300"
          >
            ❯
          </button>
        </div>

        {/* Dots indicator */}
        <div className="flex justify-center mt-4 gap-2">
          {testimonials.map((_, index) => (
            <button
              key={index}
              onClick={() => setCurrentIndex(index)}
              className={`h-2 w-2 rounded-full transition-all duration-300 ${
                index === currentIndex ? 'bg-teal-500 w-4' : 'bg-gray-300'
              }`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

// New component for testimonial card
const TestimonialCard: React.FC<{ testimonial: Testimonial }> = ({ testimonial }) => (
  <div className="flex-1 bg-white rounded-lg shadow-lg p-6 flex flex-col justify-between min-h-[400px]">
    <div>
      <blockquote className="text-gray-700 text-base md:text-lg italic mb-4">
        <span className="text-teal-500 text-4xl font-serif">"</span>
        {testimonial.quote}
      </blockquote>
    </div>
    <div className="flex flex-col items-center md:flex-row md:items-start mt-auto">
      <img
        src={testimonial.image}
        alt={testimonial.name}
        className="w-10 h-10 md:w-12 md:h-12 rounded-full mr-4"
      />
      <div className="text-center md:text-left">
        <h4 className="text-base md:text-lg font-semibold">{testimonial.name}</h4>
        <p className="text-sm md:text-base text-gray-500">{testimonial.position}</p>
      </div>
    </div>
  </div>
);

export default TestimonialCarousel;
