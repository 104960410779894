import appleStore from "../../assets/images/apple logo.svg";
import googlePlay from "../../assets/images/Play Store.svg";
import front from "../../assets/images/Phone.svg";
import logo from "../../assets/images/Tango Africa logo 1.svg";
import { FaFacebook, FaTwitter, FaInstagram } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";

const Footer = () => {
  return (
    <div className="text-white bg-black">
      <div className="bg-[#0B5B5C] px-4 pt-2 pb-2 md:pt-4 md:pb-4">
        <div className="grid grid-cols-1 md:grid-cols-2 max-w-7xl mx-auto">
          <div className="flex flex-col gap-6">
            <h1 className="text-[32px] md:text-[44px] font-bold leading-tight">
              Don't Miss Out on<br /> 
              the Fun –<br />
              Download Now!
            </h1>
            <div className="flex flex-row  gap-4">
              <a 
                href="https://apps.apple.com/app/tango-africa/id6499582097" 
                target="_blank" 
                rel="noopener noreferrer"
                className="bg-[#2F2E2E] w-full sm:w-auto px-6 py-3 flex items-center gap-2 justify-between hover:opacity-90 transition-opacity"
              >
                <span className="sm:text-sm text-xs">
                  Download on<br />
                  <span className="font-bold">Apple Store</span>
                </span>
                <img src={appleStore} alt="apple store" className="w-6" />
              </a>
              <a 
                href="https://play.google.com/store/apps/details?id=zw.co.tango.tango" 
                target="_blank" 
                rel="noopener noreferrer"
                className="bg-[#2F2E2E] w-full sm:w-auto px-6 py-3 flex items-center gap-2 justify-between hover:opacity-90 transition-opacity"
              >
                <span className="sm:text-sm text-xs">
                  Download on<br />
                  <span className="font-bold">Google Play</span>
                </span>
                <img src={googlePlay} alt="google play" className="w-6" />
              </a>
            </div>
          </div>
          <div className="hidden md:flex items-center justify-center  mb-[-16px]">
            <img src={front} alt="front" className="w-auto max-h-[400px]" />
          </div>
        </div>
      </div>

      <div className="bg-black py-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8 max-w-7xl mx-auto px-4">
          <div className="flex flex-col md:items-start items-center">
            <h3 className="text-[#0B5B5C] md:font-bold text-sm md:text-base">Phone</h3>
            <p className="text-sm md:text-base">+263 773 018 727</p>
            <p className="text-sm md:text-base">+263 781 798 350</p>
          </div>

          <div className="flex flex-col md:items-start items-center">
            <h3 className="text-[#0B5B5C] md:font-bold text-sm md:text-base">Email</h3>
            <p className="text-sm md:text-base">info@tango.co.zw</p>
          </div>

          <div className="flex flex-col md:items-start items-center text-center md:text-left">
            <h3 className="text-[#0B5B5C] md:font-bold text-sm md:text-base">Address</h3>
            <p className="text-sm md:text-base">
              Fourth Floor, 3 Anchor House,<br />
              Jason Moyo Avenue,<br />
              Harare, Zimbabwe
            </p>
          </div>

          <div className="flex flex-col md:items-start items-center">
            <h3 className="text-[#0B5B5C] md:font-bold text-sm md:text-base">Business Hours</h3>
            <p className="text-sm md:text-base">0900 - 1700</p>
            <p className="text-sm md:text-base">Monday - Saturday</p>
          </div>
        </div>

        <div className="mt-8 flex flex-col md:flex-row justify-between items-center max-w-7xl mx-auto px-4">
          <div className="flex flex-col items-center md:items-start order-2 md:order-1">
            <img src={logo} alt="Tango Africa Logo" className="w-24 mb-4" />
          </div>
          <div className="flex flex-col items-center gap-4 mb-6 md:mb-0 order-1 md:order-2">
            <h3 className="text-[#0B5B5C] text-sm">Follow us</h3>
            <div className="flex gap-4">
              <a 
                href="https://www.facebook.com/profile.php?id=61561703523510" 
                target="_blank" 
                rel="noopener noreferrer"
              >
                <FaFacebook size={20} />
              </a>
              <FaSquareXTwitter size={20} />
              <FaInstagram size={20} />
            </div>
          </div>
          <p className="text-sm order-3">Tango Africa 2025. All rights reserved.</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
