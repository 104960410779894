import React, { useState, useEffect } from 'react';
import { useAuthContext } from '../contexts/AuthContext';
import { toast } from 'react-toastify';
import { supabase } from '../Api/supabase/supabaseClient';
import { UserService } from '../Api/services/userService';

const ProfilePage: React.FC = () => {
  const { user, updateUserProfile } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    phone_number: '',
  });

  const userService = new UserService();
  const [userData,setUserData] = useState<any>(null)

  useEffect(() => {
    const fetchUserData = async () => {
      if (user?.email) {
         const userData = await userService.getUserByEmail(user.email);
        if (userData) {
          setUserData(userData)
          setFormData({
            first_name: userData.first_name || user?.user_metadata?.first_name || '',
            last_name: userData.last_name || user?.user_metadata?.last_name || '',
            phone_number: userData.phone || user?.user_metadata?.phone || '',
          });
        }
      }
    };

    fetchUserData();
  }, [user]);

  // Field validation
  const validateForm = () => {
    if (!formData.first_name.trim()) {
      toast.error('First name is required');
      return false;
    }
    if (!formData.last_name.trim()) {
      toast.error('Last name is required');
      return false;
    }
    if (!formData.phone_number.trim()) {
      toast.error('Phone number is required');
      return false;
    }
    // Basic phone number validation
    const phoneRegex = /^\+?[\d\s-]{10,}$/;
    if (!phoneRegex.test(formData.phone_number)) {
      toast.error('Please enter a valid phone number');
      return false;
    }
    return true;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateForm()) return;
    
    setLoading(true);
    try {
      // Update auth metadata
      const { error: authError } = await supabase.auth.updateUser({
        data: {
          first_name: formData.first_name,
          last_name: formData.last_name,
          phone: formData.phone_number,
        }
      });

      if (authError) throw authError;

      // Update users table
      if (user?.id) {
        const userUpdateData = {
          email: user.email!,
          first_name: formData.first_name,
          last_name: formData.last_name,
          phone: formData.phone_number,
          dob: userData?.dob || user?.user_metadata?.dob || '',
          gender: userData?.gender || user?.user_metadata?.gender || '',
        };

        const updatedUser = await userService.upsertUser(user.id, userUpdateData);
        if (!updatedUser) {
          throw new Error('Failed to update user data in database');
        }
      }

      toast.success('Profile updated successfully');
      updateUserProfile();
    } catch (error) {
      console.error('Error updating profile:', error);
      toast.error('Failed to update profile');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8 max-w-4xl">
      <div className="bg-white border border-zinc-200/50 p-4 md:p-6">
        <h1 className="text-xl md:text-2xl font-bold text-[#2F2E2E] mb-6">Profile</h1>

        <form onSubmit={handleSubmit} className="space-y-6">
          {/* Personal Information */}
          <div className="space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-600 mb-1">
                  First Name
                  <span className="text-red-500 ml-1">*</span>
                </label>
                <input
                  type="text"
                  name="first_name"
                  value={formData.first_name}
                  onChange={handleInputChange}
                  className="w-full p-2 border border-zinc-200/50 rounded-md focus:outline-none focus:border-[#0B5B5C]"
                  placeholder="Enter your first name"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-600 mb-1">
                  Last Name
                  <span className="text-red-500 ml-1">*</span>
                </label>
                <input
                  type="text"
                  name="last_name"
                  value={formData.last_name}
                  onChange={handleInputChange}
                  className="w-full p-2 border border-zinc-200/50 rounded-md focus:outline-none focus:border-[#0B5B5C]"
                  placeholder="Enter your last name"
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-600 mb-1">
                Email Address
              </label>
              <input
                type="email"
                value={user?.email || ''}
                disabled
                className="w-full p-2 border border-zinc-200/50 rounded-md bg-gray-50"
              />
              <p className="text-sm text-gray-500 mt-1">
                Email cannot be changed
              </p>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-600 mb-1">
                Phone Number
                <span className="text-red-500 ml-1">*</span>
              </label>
              <input
                type="tel"
                name="phone_number"
                value={formData.phone_number}
                onChange={handleInputChange}
                className="w-full p-2 border border-zinc-200/50 rounded-md focus:outline-none focus:border-[#0B5B5C]"
                placeholder="Enter your phone number"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-600 mb-1">
                Date of Birth
              </label>
              <input
                type="date"
                value={userData?.dob || user?.user_metadata?.dob || ''}
                disabled
                className="w-full p-2 border border-zinc-200/50 rounded-md bg-gray-50"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-600 mb-1">
                Gender
              </label>
              <input
                type="text"
                value={userData?.gender || user?.user_metadata?.gender || 'Not set'}
                disabled
                className="w-full p-2 border border-zinc-200/50 rounded-md bg-gray-50"
              />
            </div>

            <div className="pt-4">
              <button
                type="submit"
                className="w-full md:w-auto px-6 py-2 bg-[#0B5B5C] text-white rounded-md hover:bg-[#0B5B5C]/90 transition-colors disabled:bg-gray-400"
                disabled={loading}
              >
                {loading ? 'Saving...' : 'Save Changes'}
              </button>
            </div>
          </div>
        </form>

        {/* Account Statistics */}
        <div className="mt-8">
          <h2 className="text-lg font-semibold text-[#2F2E2E] mb-4">Account Statistics</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div className="bg-gray-50 p-4 border border-zinc-200/50 rounded-md">
              <p className="text-sm text-gray-600">Member Since</p>
              <p className="font-semibold text-[#2F2E2E]">
                {new Date(user?.created_at || '').toLocaleDateString()}
              </p>
            </div>
            <div className="bg-gray-50 p-4 border border-zinc-200/50 rounded-md">
              <p className="text-sm text-gray-600">Last Sign In</p>
              <p className="font-semibold text-[#2F2E2E]">
                {new Date(user?.last_sign_in_at || '').toLocaleDateString()}
              </p>
            </div>
            <div className="bg-gray-50 p-4 border border-zinc-200/50 rounded-md">
              <p className="text-sm text-gray-600">Account Status</p>
              <p className="font-semibold text-[#0B5B5C]">Active</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage; 