import React, { useState, useEffect } from "react";
import TicketCard from "../components/Cards/TicketCard";
import Pagination from "../components/Pagination/Pagination";
import { ticketOrdersService } from "../Api/services/ticketOrdersService";
import { eventsService } from "../Api/services/eventsService";
import ShimmerLoader from "../components/loaders/ShimmerLoader";
import { toast } from "react-toastify";

interface TicketOrderWithEvent {
  id: string;
  created_at: string;
  events: {
    name: string;
    venue: string;
    poster_url: string;
    start_time: string;
    category?: string;
    organizations:any
  };
  order_data: Record<string, number> | null;

  is_valid: boolean;
  hash?: string;
  admission?:any;
  ticket_name?: string;
}

const UserTickets: React.FC = () => {
  const [activeTab, setActiveTab] = useState("Upcoming");
  const [loading, setLoading] = useState(true);
  const [ticketOrders, setTicketOrders] = useState<TicketOrderWithEvent[]>([]);
  const [filteredOrders, setFilteredOrders] = useState<TicketOrderWithEvent[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const itemsPerPage = 5;

  useEffect(() => {
    const fetchTicketOrders = async () => {
      try {
        setLoading(true);
        const user = JSON.parse(localStorage.getItem("ticketing_user") || "{}");

        if (!user.id) {
          toast.error("Please login to view your tickets");
          return;
        }

        const { orders, totalCount } = await ticketOrdersService.getUserTicketOrders(
          user.id,
          currentPage,
          itemsPerPage
        );

        console.log('orderds',orders)

        setTicketOrders(orders);
        setTotalItems(totalCount);
        filterOrders(orders, activeTab);
      } catch (error) {
        console.error("Error fetching ticket orders:", error);
        toast.error("Failed to load ticket orders");
      } finally {
        setLoading(false);
      }
    };

    fetchTicketOrders();
  }, [currentPage]);

  const filterOrders = (
    orders: TicketOrderWithEvent[],
    status: string,
  ) => {
    let filtered = orders;

    switch (status) {
      case "Upcoming":
        filtered = filtered.filter(
          (order) => order.events && new Date(order.events.start_time) > new Date()
        );
        break;
      case "Completed":
        filtered = filtered.filter(
          (order) => order.events && new Date(order.events.start_time) < new Date()
        );
        break;
    }

    setFilteredOrders(filtered);
  };

  useEffect(() => {
    filterOrders(ticketOrders, activeTab);
  }, [activeTab, ticketOrders]);

  const calculateTotalTickets = (orderData: Record<string, number> | null | undefined): number => {
    if (!orderData) return 0;
    return Object.values(orderData).reduce((a, b) => a + b, 0);
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  if (loading) {
    return <ShimmerLoader />;
  }

  return (
    <div className="p-4 md:p-8">
      <h1 className="text-2xl md:text-3xl font-semibold mb-6 md:mb-8">My Tickets</h1>

      {/* Tabs - Make scrollable on mobile */}
      <div className="overflow-x-auto">
        <div className="flex space-x-4 md:space-x-6 border-b mb-6 min-w-max">
          {["Upcoming", "Completed"].map((tab) => (
            <button
              key={tab}
              onClick={() => setActiveTab(tab)}
              className={`pb-2 text-sm font-medium whitespace-nowrap ${
                activeTab === tab
                  ? "text-teal-600 border-b-2 border-teal-600"
                  : "text-gray-500 hover:text-gray-700"
              }`}
            >
              {tab}
            </button>
          ))}
        </div>
      </div>

      {/* Filters section - simplified */}
      <div className="flex flex-col md:flex-row md:justify-between md:items-center mb-6 space-y-4 md:space-y-0">
        {/* Empty div to maintain spacing if needed */}
        <div></div>
      </div>

      {/* Tickets List */}
      <div className="space-y-4">
        {filteredOrders.length > 0 ? (
          filteredOrders.map((order) =>
            order.events ? (
              <TicketCard
                key={order.id}
                id={order.id}
                date={new Date(order.events.start_time).toLocaleDateString()}
                title={order.events.name}
                location={order.events.venue}
                admission={order.admission}
                price="View Details"
                image={order.events.poster_url}
                qrCode={order.hash}
                isValid={order.is_valid}
                events={order.events}
                ticketName={order.ticket_name || 'General Admission'}
              />
            ) : (
              <div key={order.id} className="text-center py-4 text-gray-500">
                Event details not available
              </div>
            )
          )
        ) : (
          <div className="text-center py-8 text-gray-500">
            No tickets found for the selected filters
          </div>
        )}
      </div>

      {/* Pagination */}
      {filteredOrders.length > 0 && (
        <div className="mt-6 flex justify-center">
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={itemsPerPage}
            totalItemsCount={totalItems}
            pageRangeDisplayed={5}
            onChange={handlePageChange}
            innerClass="flex items-center justify-center gap-2"
            itemClass="px-3 py-1 rounded border"
            activeClass="bg-teal-600 text-white"
          />
        </div>
      )}
    </div>
  );
};

export default UserTickets;
