import { createClient } from "@supabase/supabase-js";
import { Database } from "./types";

const supabaseUrl = process.env.REACT_APP_SUPABASE_PROD_URL!;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_PROD_ANON_KEY!;

// const supabaseUrl = process.env.REACT_APP_SUPABASE_DEV_URL!;
// const supabaseAnonKey = process.env.REACT_APP_SUPABASE_DEV_ANON_KEY!;

export const supabase = createClient<Database>(supabaseUrl, supabaseAnonKey);
