import React from 'react';
import Partner1 from '../../assets/images/Bootiess.jpg'
import Partner2 from '../../assets/images/med.png'
import Partner3 from '../../assets/images/Diamond.jpg'


const partners = [
  { id: 1, name: 'Booties', logo: Partner1 },
  { id: 2, name: 'Med Orange', logo: Partner2 },
  { id: 3, name: 'Diamond', logo: Partner3 },
 
];

const PartnerLogos: React.FC = () => {
  return (
    <div className="bg- py-8 px-4 md:py-12">
      <div className="container mx-auto text-center">
        <h2 className="text-2xl md:text-3xl font-bold text-gray-800 mb-10">
        Our Ticket Distributor Partners
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-6 items-center justify-items-center">
          {partners.map((partner) => (
            <div key={partner.id} className={`w-full ${partner.id === 2 ? 'max-w-[240px]' : 'max-w-[150px]'}`}>
              <img
                src={partner.logo}
                alt={partner.name}
                className="w-full h-auto "
                style={{
                  maxHeight: '150px',
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PartnerLogos;
