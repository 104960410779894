import React from "react";
import { FaChartBar, FaBroadcastTower, FaAddressBook, FaMoneyBillWave, FaTicketAlt, FaMobileAlt, FaBell, FaClipboardList, FaClock } from "react-icons/fa";

const features = [
  {
    icon: <FaChartBar size={24} />,
    title: "Analytics",
    description:
      "Gain deep insights into your event performance with advanced analytics. Track everything from ticket sales and revenue to event trends and attendee demographics. Stay on top of the action as it happens.",
  },
  {
    icon: <FaBroadcastTower size={24} />,
    title: "Broadcasts",
    description:
      "Promote your event effortlessly with Tango broadcasts. Send targeted invites to your followers, past attendees, or new audiences. Reach your potential ticket buyers quickly and efficiently, making event promotion simpler than ever.",
  },
  {
    icon: <FaAddressBook size={24} />,
    title: "Contacts",
    description:
      "Build and organize your valuable contact database with ease. Keep track of your followers, past attendees, and potential guests in one central place. Segment and manage your audience data efficiently, helping you build lasting relationships with your event community.",
  },
  {
    icon: <FaMoneyBillWave size={24} />,
    title: "Payout",
    description:
      "Get your ticket earnings the way you prefer - directly to your bank account, EcoCash wallet, or in person. Choose flexible payout schedules with daily or weekly transfers. Access your funds quickly and securely, keeping your cash flow smooth and predictable.",
  },
  {
    icon: <FaTicketAlt size={24} />,
    title: "Flexible Ticketing",
    description:
      "Create the perfect ticket mix for your event with our comprehensive ticketing options. From standard and early bird tickets to donation-based entry, multi-day passes, and exclusive member-only access. Design your ideal ticketing strategy with options that match your event's unique requirements.",
  },
  {
    icon: <FaMobileAlt size={24} />,
    title: "Ticket Scanner App",
    description:
      "Streamline check-ins with our mobile-friendly scanner app, designed for fast and efficient processing. Faster entry means a better experience for your attendees. Validating tickets has never been easier."
  },
  {
    icon: <FaBell size={24} />,
    title: "Notifications",
    description:
      "Stay in the loop with instant updates on your ticket sales and event performance. Receive real-time alerts when tickets are purchased, track daily sales notifications, and get timely updates on your event's progress. Keep your finger on the pulse of your event's success.",
  },
  {
    icon: <FaClipboardList size={24} />,
    title: "Event Registration",
    description:
      "Customize your ticket-buying experience with tailored registration forms. Collect essential attendee information, create custom questions, and gather valuable data to better understand your audience. Make informed decisions and deliver exceptional events with rich attendee insights.",
  },
  {
    icon: <FaClock size={24} />,
    title: "Program Lineup",
    description:
      "Keep your attendees informed with a detailed schedule of your event's activities. Create a comprehensive timeline showing what's happening, when, and where. From keynote speakers to performance times, give your audience a clear view of your event's exciting schedule. Help attendees plan their experience perfectly.",
  },
];

const FeaturesGrid: React.FC = () => {
  return (
    <div className="max-w-6xl mx-auto my-8 p-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
      {features.map((feature, index) => (
        <div key={index} className="flex flex-col items-center md:items-start bg-white shadow-md rounded-lg p-6">
          <div className="bg-[#0B5B5C] text-white rounded-full p-2 mb-4">{feature.icon}</div>
          <h3 className="text-xl font-bold text-[#0B5B5C] mb-2 text-center md:text-left">{feature.title}</h3>
          <p className="text-gray-700 text-center md:text-left">{feature.description}</p>
        </div>
      ))}
    </div>
  );
};

export default FeaturesGrid;
